import React from "react";

const Typography = () => (
  <section className="section typography">
    <div className="container">
      <h1 className="title is-3">TYPOGRAPHY</h1>
      <hr />
      <div className="columns">
        <div className="column one-thirds-desktop poppins">
          <h2 className="title is-2">POPPINS</h2>
          <h5 className="title black">HEADER</h5>
          <br />
          <div className="semi-bold">
            POPPINS
            <br />
            SEMIBOLD
            <br />
            ABCDEFGHIJKLMNOPQRS
            <br />
            ABCDEFGHIJKLMNOPQRSTUVWXYZ
            <br />
            1234567890@#$%&amp;*
          </div>
          <div className="bold">
            <br />
            POPPINS BOLD
            <br />
            ABCDEFGHIJKLMNOPQRS
            <br />
            ABCDEFGHIJKLMNOPQRSTUVWXYZ
            <br />
            1234567890@#$%&amp;*
          </div>
          <div className="black">
            <br />
            POPPINS BLACK
            <br />
            ABCDEFGHIJKLMNOPQRS
            <br />
            ABCDEFGHIJKLMNOPQRSTUVWXYZ
            <br />
            1234567890@#$%&amp;*
          </div>
        </div>

        <div className="column two-thirds-desktop roboto">
          <h1 className="title is-2">Roboto</h1>
          <h5 className="title black">Body</h5>

          <div className="columns">
            <div className="column">
              <div className="light">
                Roboto Light
                <br />
                ABCDEFGHIJKLMNOPQRS
                <br />
                abcdefghijklmnopqrstuvwxyz
                <br />
                1234567890@#$%&amp;*
                <br />
                <br />
              </div>
              <div className="light italic">
                Roboto Light Italic
                <br />
                ABCDEFGHIJKLMNOPQRS
                <br />
                abcdefghijklmnopqrstuvwxyz
                <br />
                1234567890@#$%&amp;*
                <br />
                <br />
              </div>
              <div className="medium">
                Roboto Medium
                <br />
                ABCDEFGHIJKLMNOPQRS
                <br />
                abcdefghijklmnopqrstuvwxyz
                <br />
                1234567890@#$%&amp;*
                <br />
                <br />
              </div>
              <div className="medium italic">
                Roboto Medium Italic
                <br />
                ABCDEFGHIJKLMNOPQRS
                <br />
                abcdefghijklmnopqrstuvwxyz
                <br />
                1234567890@#$%&amp;*
              </div>
            </div>
            <div className="column">
              <div className="bold">
                Roboto Bold
                <br />
                ABCDEFGHIJKLMNOPQRS
                <br />
                abcdefghijklmnopqrstuvwxyz
                <br />
                1234567890@#$%&amp;*
                <br />
                <br />
              </div>
              <div className="bold italic">
                Roboto Bold Italic
                <br />
                ABCDEFGHIJKLMNOPQRS
                <br />
                abcdefghijklmnopqrstuvwxyz
                <br />
                1234567890@#$%&amp;*
                <br />
                <br />
              </div>
              <div className="black">
                Roboto Black
                <br />
                ABCDEFGHIJKLMNOPQRS
                <br />
                abcdefghijklmnopqrstuvwxyz
                <br />
                1234567890@#$%&amp;*
                <br />
                <br />
              </div>
              <div className="black italic">
                Roboto Black Italic
                <br />
                ABCDEFGHIJKLMNOPQRS
                <br />
                abcdefghijklmnopqrstuvwxyz
                <br />
                1234567890@#$%&amp;*
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
export default Typography;
