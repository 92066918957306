import React from "react";

const PageTop = props => (
  <div className="section page-top">
    <div className="container">
      <h1 className="title title-2">{props.title}</h1>
    </div>
  </div>
);

export default PageTop;
