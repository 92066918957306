import React from "react";
import Helmet from "react-helmet";

import Typography from "../components/styleguide/typography";
import PageTop from "../components/Layout/page_top";
import { SEO } from "src/components/commonComponents";
const StyleGuidePage = () => (
  <div>
    <SEO
      pageUrl="/styleguide"
    />
    <Helmet bodyAttributes={{ class: "styleguide" }} title="Style Guide" />
    <PageTop title={"Style Guide"} />
    <Typography />
  </div>
);

export default StyleGuidePage;
